import * as React from "react"
import * as styles from "./blog-page.module.scss"

import Button from "../components/button"
import Footer from "../components/v2/footer"
import Header from "../components/v2/header"
import SEO from "../components/seo"
import Section from "../components/section"
import { graphql } from "gatsby"

const BlogLandingTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO
        title={ frontmatter.title }
        article={ true }
      />
      <Header/>
      <main className={ styles.blogPage }>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">{ frontmatter.title }</h1>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <h2 className="heading-sm color-muted-foreground">{ frontmatter.description }</h2>
              <div className="d-flex justify-content-center" style={ { marginTop: `2rem` } }>
                <Button theme="primary" size="large" href={ frontmatter.btn } style={ { padding: `0.5rem 2rem` } }>{ frontmatter.btnLabel }</Button>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" className={ styles.content }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default BlogLandingTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        btn
        btnLabel
      }
    }
  }
`